import { globalHistory, Location } from "@mgdx-libs/router";
import { GatsbyBrowser, GatsbySSR } from "gatsby";
import React from "react";
import { QueryParamProvider } from "use-query-params";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] & GatsbySSR["wrapRootElement"] = ({ element }) => (
  <Location>
    {({ location }) => (
      <QueryParamProvider location={location} reachHistory={globalHistory}>
        {element}
      </QueryParamProvider>
    )}
  </Location>
);
