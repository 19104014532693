import { VConsoleProvider } from "@mgdx/shared/src/providers/VConsoleProvider";
import { ErrorBoundary, errorHandlerReport } from "@mgdx-libs/error-handler";
import { GatsbyBrowser, GatsbySSR } from "gatsby";
import React from "react";

const RootElement: React.FC<React.PropsWithChildren<EmptyObject>> = ({ children }) => (
  <ErrorBoundary errorHandlerReport={errorHandlerReport}>
    <VConsoleProvider>{children}</VConsoleProvider>
  </ErrorBoundary>
);

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] & GatsbySSR["wrapRootElement"] = ({ element }) => (
  <RootElement>{element}</RootElement>
);
